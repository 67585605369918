<template>
  <LiefengContent>
    <template v-slot:title>赞助商管理
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarRight>
       <Input
       :maxlength="20"
        v-model.trim="searchData.queryCondition"
        enter-button
        placeholder="对接人或手机号"
        style="width: 150px; margin-right: 10px"
        @on-enter="getList"
      />
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="ios-search"
        @click="getList"
      >
        查询</Button
      ><Button
        style="margin: 0 10px 0 0"
        icon="ios-refresh"
        type="success"
        @click="rest"
      >
        重置</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="md-add"
        @click="addUser"
      >
        新增赞助商</Button
      >
       <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="pageNumber"
        @hadlePageSize="getList"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>
      <!-- 新增赞助商 -->
      <LiefengModal
      width="520px"
      height="calc(100vh - 200px)"
        :title="addChangeTitle"
        :value="addSponsor"
        @input="addSponsorFn"
      >
        <template v-slot:contentarea>
          <Form
            class="addform"
            :model="addformRight"
            ref="addformRight"
            :rules="ruleValidate"
            label-position="right"
            :label-width="120"
            style="width: 500px"
          >
            <FormItem label="赞助商名称" prop="name">
              <Input :maxlength="50" v-model.trim="addformRight.name" style="width: 350px" />
            </FormItem>
            <FormItem label="赞助商类型" prop="sponsorType">
              <Select v-model="addformRight.sponsorType" style="width: 350px">
                <Option
                  :value="item.value"
                  v-for="(item, index) in sponsorType"
                  :key="index"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="联系人" prop="contact">
              <Input
                v-model.trim="addformRight.contact"
                placeholder=""
                :maxlength="50"
                style="width: 350px"
              />
            </FormItem>
            <FormItem label="联系电话" prop="mobile">
              <Input
              :maxlength="20"
                v-model.trim="addformRight.mobile"
                placeholder=""
                style="width: 350px"
              />
            </FormItem>
            <FormItem label="邮箱" prop="email">
              <Input
                v-model.trim="addformRight.email"
                placeholder=""
                :maxlength="50"
                style="width: 350px"
              />
            </FormItem>
            <FormItem label="登记时间">
              <DatePicker
                @on-change="formatTime"
                format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                v-model="defaultTime"
                placeholder="默认当前时间"
                style="width: 200px"
              ></DatePicker>
            </FormItem>
            <FormItem label="备注">
              <textarea
                v-model="addformRight.remark"
                cols="30"
                rows="2"
                :maxlength="200"
                style="width: 350px"
              ></textarea>
            </FormItem>
          </Form>
        </template>
          <template v-slot:toolsbar>
              <Button type="info" @click="addSponsorFn(false)">取消</Button>
              <Button style="margin-left: 10px;" type="primary" @click="saveSponsor('addformRight')"
                >保存</Button
              >
        </template>
      </LiefengModal>
      <!-- 新增商品 -->
      <LiefengModal
      width="540px"
      height="calc(100vh - 200px)"
        title="新增商品"
        :value="addshop"
        @input="addShopFn"
      >
        <template v-slot:contentarea>
          <Form
            class="addform"
            :model="addformShop"
            ref="addformShop"
            :rules="shopValidate"
            label-position="right"
            :label-width="125"
            style="width: 520px"
          >
            <FormItem label="商品名称" prop="name">
              <Input :maxlength="50" v-model.trim="addformShop.name" style="width: 350px" />
            </FormItem>

            <FormItem label="商品类型" prop="prizeType">
              <Select v-model="addformShop.prizeType" style="width: 350px">
                <Option
                  :value="item.value"
                  v-for="(item, index) in prizeType"
                  :key="index"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="数量" prop="total">
               <InputNumber @on-change="(val) => {addformShop.total = Number(addformShop.total.toString().replace('.',''))}" :max='999999999' :min="1" v-model="addformShop.total" style="width: 350px" ></InputNumber>
            </FormItem>

            <FormItem label="单位" prop="unit">
              <Input
                v-model.trim="addformShop.unit"
                :maxlength="10"
                style="width: 350px"
              />
            </FormItem>
            <FormItem label="价值金额（元）" prop="price">
              <InputNumber :max='999999999' :min="1" v-model="addformShop.price" style="width: 350px" ></InputNumber>
            </FormItem>
            <FormItem label="赞助时间">
              <DatePicker
                @on-change="formatTime"
                format="yyyy-MM-dd HH:mm"
                type="datetime"
                v-model="helpDefaultTime"
                placeholder="默认当前时间"
                style="width: 200px"
              ></DatePicker>
            </FormItem>

            <FormItem label="商品图片">
              <img
                :src="addformShop.image"
                alt=""
                v-if="addformShop.image != ''"
                width="200"
              />
              <Upload
                :before-upload="handleUpload"
                :data="uploadParams"
                :on-success="successUpload"
                :action="action"
                :default-file-list="imageList"
                :format="['jpg', 'png', 'jpeg', 'gif']"
                :on-format-error="uploadFormatError"
                 :on-remove="uploadRemove"
                 accept=".jpg,.png,.jpeg,.gif"
              >
                <Button>上传图片</Button>
              </Upload>
            </FormItem>
            <FormItem label="备注">
              <textarea
                v-model="addformShop.remark"
                :maxlength="200"
                cols="30"
                rows="2"
                style="width: 350px"
              ></textarea>
            </FormItem>
            <FormItem>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
              <Button type="info" @click="addShopFn(false)">取消</Button>
              <Button style="margin-left: 10px;" type="primary" @click="saveShop('addformShop')"
                >保存</Button
              >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/sponsorindex")
import {format} from "@/utils/formatTime";
import {validateMobile,validateEmail} from "@/utils/validate";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      hasSensitive:true,
      loading: false,
       imageList: [],
      imageSrc: "",
      uploadParams: {},
      action: "",
      uploadPath: "",
      uploadName: "",
      hideSearch: false,
      showDo: false, //false 为隐藏
      hideDel: true,
      hideEdit: true,
      hidenBread: true,
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      talbeColumns: [
        {
          title: "赞助商编码",
          key: "code",
          width: 120,
          align: "center",
        },
        {
          title: "赞助商名称",
          key: "name",
          minWidth: 120,
          align: "center",
        },
        {
          title: "赞助商类型",
          key: "sponsorType",
          width: 120,
          align: "center",
        },
        {
          title: "对接人",
          key: "contact",
          width: 120,
          align: "center",
        },
        {
          title: "联系电话",
          key: "mobile",
          align: "center",
          width: 130,
        },
        {
          title: "邮箱",
          key: "email",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.email,
                  },
                  on: {
                    click: (e) => {
                      console.log(11111);
                      e.stopPropagation();
                    },
                  },
                },
                params.row.email
              ),
            ]);
          },
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 100,
          // align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.remark,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.remark
              ),
            ]);
          },
        },
        {
          title: "登记时间",
          key: "gmtCreate",
          align: "center",
          width: 180,
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    // trigger: "click",
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down"
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.edit(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.remove(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.addShopList(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "添加商品"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.selectShop(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看商品"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.cantactUser(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "关联员工"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      searchData: {
        queryCondition: "",
        pageSize: this.pageSize,
        page: 1,
      },
      addSponsor: false,
      addshop: false,
      addChangeTitle: "",
      url: "",
      sponsorList: [],
      sponsorType: [
        {
          value: "1",
          label: "个人",
        },
        {
          value: "2",
          label: "企业",
        },
        {
          value: "3",
          label: "单位",
        },
      ],
      prizeType: [
        {
          value: "1",
          label: "实物商品",
        },
        {
          value: "2",
          label: "线下消费券",
        },
        {
          value: "3",
          label: "线上消费券",
        },
      ],
      defaultTime: this.defaultTime ? this.defaultTime : new Date(),
      helpDefaultTime: this.helpDefaultTime ? this.helpDefaultTime : new Date(),
      addformRight: {
        contact: "",
        name: "",
        code: "",
        mobile: "",
        email: "",
        sponsorType: "",
        sponsorId: "",
        gmtCreate: "",
        remark: "",
        oemCode: "",
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: validateMobile,
            trigger: "blur",
          },
        ],
        email: [
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "联系人不能为空",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "赞助商编码不能为空",
            trigger: "blur",
          },
        ],
        sponsorType: [
          {
            required: true,
            message: "赞助商类型不能为空",
            trigger: "blur",
          },
        ],
      },
      shopValidate: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            type: 'number',
            message: "单价不能为空",
            trigger: "change",
          },
        ],
        total: [
          {
            required: true,
             type: 'number',
            message: "数量不能为空",
            trigger: "change",
          },
        ],
      },
      addformShop: {
        name: "",
        prizeType: "",
        total: 1,
        // activityCode:"",
        unit: "",
        price: 1,
        sponsorId: "",
        remark: "",
        gmtCreate: "",
        oemCode: "",
        image: "",
      },

      orgCode: "",
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "赞助商编码",
            "赞助商名称",
            "赞助商类型",
            "对接人",
            "联系电话",
            "邮箱",
            "备注",
            "登记时间",
          ];
          let filterVal = [
            "code",
            "name",
            "sponsorType",
            "contact",
            "mobile",
            "email",
            "remark",
            "gmtCreate"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "赞助商列表"
          );
        },
      });
    },
    addSponsorFn(status) {
      this.addSponsor = status;
    },
    addShopFn(status) {
      this.addshop = status;
    },
     uploadFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" + file.name + "格式不正确，请上传jpg,png,jpeg,gif格式的图片",
      });
    },
    handleUpload(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = Date.parse(new Date()) / 1000;
          this.autograph = res.data;
          // this.action = "/ossimage";
          this.action =  window.vue.getProxy()['/oss'].target; //测试环境用
          this.uploadParams = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.uploadName = now + file.name;
          this.uploadPath = res.data.host + res.data.key;
          this.imageList = [];
        }
      });
    },
     //移除图片
    uploadRemove(file,fileList) {
      this.imageList = [];
      this.addformShop.image = '';
    },
    successUpload(res, file) {
      if (file.status == "finished") {
        this.$Message.success({
          background: true,
          content: "图片上传成功！",
        });
        this.imageList = [
          {
            name: file.name,
            url: this.uploadPath + this.uploadName,
          },
        ];
        this.addformShop.image = this.uploadPath + this.uploadName;
        // this.imageSrc = this.uploadPath + this.uploadName;
      }
    },
    rest() {
      this.searchData.queryCondition = "";
      this.pageNumber = 1;
      this.getList();
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.getList()
    },
    getList(data) {
      this.loading = true;
      if (data && data.page) {
        this.searchData.page = data.page;
        this.searchData.pageSize = data.pageSize;
        this.pageNumber = data.page;
        this.pageSize = data.pageSize;
      } else {
        this.searchData.page = this.pageNumber;
        this.searchData.pageSize = this.pageSize;
      }
      if (data && data.cancelable) {
        //点击查询时  查询返回第一页的数据
        this.searchData.page = 1;
        this.searchData.queryCondition = this.searchData.queryCondition;
        this.pageNumber = 1;
      }
      this.$post(
        "/old/api/pc/componentPrize/getPrizeSponsorPage",{
          ...this.searchData,
          hasSensitive:this.hasSensitive,
          orgCode:parent.vue.loginInfo.userinfo.orgCode,
        }
        
        
      )
        .then((res) => {
          if (res.code === "200") {
            this.total = res.maxCount;
            res.dataList.forEach((item) => {
              item.gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss");
              item.sponsorType =
                item.sponsorType == "1"
                  ? "个人"
                  : item.sponsorType == "2"
                  ? "企业"
                  : item.sponsorType == "3"
                  ? "单位"
                  : "";
            });
            this.tableData = res.dataList;
            this.loading = false;
          }else {
            this.$Message.error({
              background: true,
              content: "数据获取失败"
            })
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSelection() {},
    addUser() {

      this.$refs.addformRight.resetFields();
      this.addSponsor = true;
      this.addChangeTitle = "新增赞助商";
      this.url = "/old/api/pc/componentPrize/addPrizeSponsor";
      this.addformRight = {};
    },
    importFile() {},
    addShopList(params) {
      this.imageList = [];
      this.addshop = true;
      this.title = "新增商品";
      this.url = "/old/api/pc/componentPrize/addPrizeGoods";

      this.addformShop.sponsorId = params.row.sponsorId;
    },
    selectShop(data) {
      this.$router.push({
        path: "/sponsorshopmanage",
        query: {
          sponsorId: String(data.row.sponsorId),
        },
      });
    },
    cantactUser(data) {
      let Base64 = require("js-base64").Base64;

      this.$router.push({
        path: "/sponsorcontactuser",
        query: {
          data: Base64.encode(JSON.stringify(data)),
        },
      });
      this.userModal = true;
      // console.log(data)
      this.orgCode = data.row.orgCode;
    },

    saveSponsor(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addformRight.gmtCreate = format(
            this.defaultTime,
            "yyyy-MM-dd HH:mm:ss"
          );
          this.addformRight.oemCode = parent.vue.oemInfo.oemCode;

          console.log("this.addformRight");
          console.log(typeof this.addformRight.sponsorId);
          console.log(this.addformRight.sponsorId);

          this.$post(this.url, this.addformRight)
            .then((res) => {
              console.log(res);
              if (res.code === "200") {
                this.$Message.success("操作成功");
                this.addSponsor = false;
                this.getList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    saveShop(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          (this.addformShop.gmtCreate = format(
            this.helpDefaultTime,
            "yyyy-MM-dd HH:mm:ss"
          )),
          this.addformShop.oemCode = parent.vue.oemInfo.oemCode;
          this.$post(this.url, this.addformShop)
            .then((res) => {
              if (res.code === "200") {
                this.$Message.success("操作成功");
                this.addshop = false;
                this.addformShop = {
                  total: 1,
                   price: 1,
                };
                // this.getList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    edit(params) {
      this.$refs.addformRight.resetFields();
      this.addChangeTitle = "修改赞助商";
      this.addSponsor = true;
      this.url = "/old/api/pc/componentPrize/updatePrizeSponsor";
      this.addformRight = JSON.parse(JSON.stringify(params.row));
      // let obj = params.row
      // console.log(obj)
      // Object.keys(this.addformRight).forEach(item => { // 别遍历赋值 这有坑
      //     this.addformRight[item] = obj[item]
      // })
      console.log(this.addformRight);

      this.addformRight.sponsorType =
        params.row.sponsorType === "个人"
          ? "1"
          : params.row.sponsorType === "企业"
          ? "2"
          : params.row.sponsorType === "单位"
          ? "3"
          : "";

      this.defaultTime = params.row.gmtCreate;
      console.log(this.defaultTime);
    },
    remove(params) {
      // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
      // console.log(this.total)
      // console.log(this.pageSize)
      // console.log(this.pageNumber)
      let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
      this.pageNumber =
        this.pageNumber > totalPage ? totalPage : this.pageNumber;
      // console.log(this.pageNumber)
      this.pageNumber = this.pageNumber < 1 ? 1 : this.pageNumber;
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>确认删除数据</p>",
        cancelText: "取消",
        okText: "确认",
        onOk: () => {
          this.$post("/old/api/pc/componentPrize/deletePrizeSponsor", {
            sponsorId: params.row.sponsorId,
            orgCode: params.row.orgCode,
          })
            .then((res) => {
              if (res.code === "200") {
                this.$Message.success("操作成功");
                let data = {
                  pageNum: this.pageNumber,
                  pageSize: this.pageSize,
                };
                this.getList();
              }else {
                this.$Message.error({
                  background: true,
                  content: res.desc
                })
              }
              if (res.code === "501") {
                this.$Message.error(res.desc);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    formatTime(date) {
      console.log(date);
    },
  },
   mounted() {
    this.getList();
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>